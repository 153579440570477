// src/components/CourseList.js
import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Button, Modal, Card } from '@rewind-ui/core';
import { Table } from '@rewind-ui/core';

import { useToast } from '@rewind-ui/core';


import { copyToClip } from '../lib/utils'

import { ExternalLink, Copy, Trash2, X } from 'lucide-react';

const CourseList = ({ courses, fetchCourses, accessKey }) => {
    const [open, setOpen] = useState(false);
    const [courseToDelete, setCourseToDelete] = useState(null);
    const navigate = useNavigate();
    const toast = useToast();

    const [searchTerm, setSearchTerm] = useState('');
    const [sortKey, setSortKey] = useState('intTitle');
  
    // Function to handle sorting
    const handleSort = (key) => {
      setSortKey(key);
    };
  
    // Function to sort the courses array based on the sort key
    const sortedCourses = [...courses].sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return -1;
      }
      if (a[sortKey] > b[sortKey]) {
        return 1;
      }
      return 0;
    });

    const filteredCourses = sortedCourses
    .filter(course =>
      course.intTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.extTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.type?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => b.id - a.id);

    const handleDelete = async () => {
        if (courseToDelete) {
            try {
                await axios.delete(`https://ob.volsky.pro/rapi/courses/${courseToDelete}`, {
                    headers: {
                        Authorization: `Bearer ${accessKey}`
                    }
                });
                fetchCourses();
                setOpen(false);
                setCourseToDelete(null);
                toast.add({
                    closeOnClick: true,
                    color: 'green',
                    description: 'Курс удалён из БД',
                    duration: 1000,
                    iconType: 'success',
                    variant: 'success',
                    pauseOnHover: true,
                    radius: 'lg',
                    shadow: 'none',
                    shadowColor: 'none',
                    showProgress: true,
                    title: 'Успешно!',
                    tone: 'solid',
                });

            } catch (err) {
                toast.add({
                    closeOnClick: true,
                    color: 'red',
                    description: err,
                    duration: 1000,
                    iconType: 'warning',
                    variant: 'warning',
                    pauseOnHover: true,
                    radius: 'lg',
                    shadow: 'none',
                    shadowColor: 'none',
                    showProgress: true,
                    title: 'Ошибка!',
                    tone: 'solid',
                });
            }
        };
    };

    const openDeleteModal = (id) => {
        setCourseToDelete(id);
        setOpen(true);
    };

    const handleEdit = (id) => {
        navigate(`/edit/${id}`);
    };

/*     useEffect(() => {
        const element = document.getElementById('fkthd');
        if (element) {
          element.classList.add('sticky', 'top-0');
        }
      }, []); */

    return (
        < div  className='' >
            <Modal open={open} onClose={() => setOpen(false)}>
                <Card className='p-5 grid gap-4 grid-cols-1'>
                    <h2 className='text-2xl text-red-700'>Подтвердите удаление?</h2>
                    <p>Это сотрёт курс из базы вообще совсем!</p>
                    <Button className='bg-red-400' onClick={handleDelete}><Trash2 /> Удалить</Button>
                    <Button className='bg-gray-400' onClick={() => setOpen(false)}><X /> Отмена</Button>
                </Card>
            </Modal>
            <Table headerColor="white" outerBorders={false} verticalBorders={true} className='overflow-y-scroll h-screen'>
                <Table.Thead id='fkthd' >
                    <Table.Tr color="green" >
                        <Table.Th>ID БД</Table.Th>
                        <Table.Th>Внтр. название ГК
                     <div className='flex'>   <input
          type="text"
          className="w-full p-1 border border-gray-300 rounded"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Поиск..."
        />
        <X className='text-red-500' onClick={(e) => setSearchTerm('')} /></div>
                        </Table.Th>
                        <Table.Th>Внешнее название</Table.Th>
                        <Table.Th>Тип</Table.Th>
                        <Table.Th>Дескрипт</Table.Th>
                        <Table.Th>Описание</Table.Th>
                        <Table.Th>Заметки</Table.Th>
                        <Table.Th>Цена</Table.Th>
                        <Table.Th>Активен</Table.Th>
                        <Table.Th>Лендинг</Table.Th>
                        <Table.Th>ГК курс</Table.Th>
                        <Table.Th>ГК ID</Table.Th>
                        <Table.Th>ГК про<br />дукт</Table.Th>
                        <Table.Th>ГК пре<br />длож</Table.Th>
                        <Table.Th>ГК вид<br />жет</Table.Th>
                        <Table.Th>Тильда</Table.Th>
                        <Table.Th>Тапл / TG</Table.Th>
                        <Table.Th>Действия</Table.Th>
                    </Table.Tr>
                   
                </Table.Thead>
                <Table.Tbody>
                    {filteredCourses.map((course) => (
                        <Table.Tr key={course.id}>
                            <Table.Td>{course.id}</Table.Td>
                            <Table.Td onClick={() => copyToClip(course.intTitle)}>{course.intTitle}</Table.Td>
                            <Table.Td onClick={() => copyToClip(course.extTitle)}>{course.extTitle}</Table.Td>
                            <Table.Td onClick={() => copyToClip(course.type)}>{course.type}</Table.Td>
                            <Table.Td onClick={() => copyToClip(course.descript)}>{course.descript}</Table.Td>
                            <Table.Td onClick={() => copyToClip(course.about)}>{course.about}</Table.Td>
                            <Table.Td onClick={() => copyToClip(course.notes)}>{course.notes}</Table.Td>
                            <Table.Td onClick={() => copyToClip(course.price)}>{course.price}</Table.Td>
                            <Table.Td>{course.active ? 'Да' : 'Нет'}</Table.Td>
                            <Table.Td>
                                {course.urlLanding && (
                                    <>
                                        <a href={course.urlLanding} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(course.urlLanding)}
                                        />
                                    </>
                                )}

                            </Table.Td>
                            <Table.Td>
                                {course.urlCourse && (
                                    <>
                                        <a href={course.urlCourse} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(course.urlCourse)}
                                        />
                                    </>
                                )}
                            </Table.Td>


                            <Table.Td>
                                {course.getcID && (
                                    <>
                                        <a href={`https://rojdenie-webinar.ru/teach/control/stream/view/id/${course.getcID}`} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(course.getcID)}
                                        />
                                    </>
                                )}
                            </Table.Td>
                            <Table.Td>
                                {course.getcProduct && (
                                    <>
                                        <a href={`https://rojdenie-webinar.ru/pl/sales/product/update?id=${course.getcProduct}`} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(`https://rojdenie-webinar.ru/pl/sales/product/update?id=${course.getcProduct}`)}
                                        />
                                    </>
                                )}
                            </Table.Td>
                            <Table.Td>
                                {course.getcOffer && (
                                    <>
                                        <a href={`https://rojdenie-webinar.ru/pl/sales/offer/update?id=${course.getcOffer}`} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(`https://rojdenie-webinar.ru/pl/sales/offer/update?id=${course.getcOffer}`)}
                                        />
                                    </>
                                )}
                            </Table.Td>
                            <Table.Td>
                                {course.getcWidget && (
                                    <>
                                        <a href={`https://rojdenie-webinar.ru/pl/lite/widget/editor?id=${course.getcWidget}`} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(`https://rojdenie-webinar.ru/pl/lite/widget/editor?id=${course.getcWidget}`)}
                                        />
                                    </>
                                )}
                            </Table.Td>
                            <Table.Td>
                                {course.tilda && (
                                    <>
                                        <a href={course.tilda} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(course.tilda)}
                                        />
                                    </>
                                )}
                            </Table.Td>
                            <Table.Td>
                                {course.taplink && (
                                    <>
                                        <a href={course.taplink} target="_blank" rel="noopener noreferrer">
                                            <ExternalLink />
                                        </a>
                                        <Copy
                                            className="cursor-pointer mt-2"
                                            onClick={() => copyToClip(course.taplink)}
                                        />
                                    </>
                                )}
                            </Table.Td>
                            <Table.Td>
                                <Button className='bg-green-400' onClick={() => handleEdit(course.id)}>Редактировать</Button>
                                <Button className='bg-red-400 mt-2' onClick={() => openDeleteModal(course.id)}>Удалить</Button>
                            </Table.Td>
                        </Table.Tr>
                    ))}
                </Table.Tbody>
            </Table>
            <Button  className='fixed bottom-0 left-0 text-black hover:text-white bg-yellow-400 mt-2'  onClick={() =>  navigate(`/create/`)}>Создать новый курс</Button>
        </div>
    );
};

export default CourseList;
