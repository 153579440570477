// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import EditCourse from './pages/EditCourse';
import CreateCourse from './pages/CreateCourse';
import Layout from './components/Layout';


const App = () => {
  const [accessKey, setAccessKey] = useState('');

  useEffect(() => {
    // Load the access key from local storage when the app initializes
    const storedAccessKey = localStorage.getItem('accessKey');
    if (storedAccessKey) {
      setAccessKey(storedAccessKey);
    }
  }, []);

  const handleSetAccessKey = (key) => {
    setAccessKey(key);
    localStorage.setItem('accessKey', key);
  };

  return (
    <Router>
      <Layout>
      <Routes>
        <Route path="/" element={<Home accessKey={accessKey} setAccessKey={handleSetAccessKey} />} />
        <Route path="/edit/:id" element={<EditCourse accessKey={accessKey} />} />
        <Route path="/create" element={<CreateCourse accessKey={accessKey} />} />


      </Routes>
      </Layout>
    </Router>
  );
};

export default App;