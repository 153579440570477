// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CourseList from '../components/CourseList';

const Home = ({ accessKey, setAccessKey }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (accessKey) {
      fetchCourses();
    }
  }, [accessKey]);

  const fetchCourses = async () => {
    const response = await axios.get('https://ob.volsky.pro/rapi/courses', {
      headers: {
        Authorization: `Bearer ${accessKey}`
      }
    });
    setCourses(response.data.reverse());
  };

  if (!accessKey) {
    return (
        <div>
       
          <input
            type="text"
            placeholder="Enter Access Key"
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
          />
       
        </div>
      );
  } else {
  return (
    <div>

      <CourseList courses={courses} fetchCourses={fetchCourses} accessKey={accessKey} />
    </div>
  );
}
};

export default Home;
