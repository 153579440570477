// src/pages/EditCourse.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "@rewind-ui/core";
import { copyToClip } from "../lib/utils";

const EditCourse = ({ accessKey }) => {
  const toast = useToast();
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const navigate = useNavigate();
  

  useEffect(() => {
    if (!accessKey) {
      navigate("/");
    }
    const fetchCourse = async () => {
      const response = await axios.get(
        `https://ob.volsky.pro/rapi/courses/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessKey}`,
          },
        }
      );
      setCourse(response.data);
    };

    if (accessKey) {
      console.log("123123");
      fetchCourse();
    }
  }, [id, accessKey]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourse({ ...course, [name]: value });
  };


  const handleCancel = async () => {
    setCourse(null)
    navigate("/");
  };

  const handleSave = async () => {
    try {
      await axios.put(`https://ob.volsky.pro/rapi/courses/${id}`, course, {
        headers: {
          Authorization: `Bearer ${accessKey}`,
        },
      });
      toast.add({
        closeOnClick: true,
        color: "green",
        description: "Возвращаю к списку",
        duration: 1000,
        iconType: "success",
        variant: "success",
        pauseOnHover: true,
        radius: "lg",
        shadow: "none",
        shadowColor: "none",
        showProgress: true,
        title: "Сохранено!",
        tone: "solid",
      });
      navigate("/");
    } catch (err) {
      toast.add({
        closeOnClick: true,
        color: "red",
        description: err,
        duration: 1000,
        iconType: "warning",
        variant: "warning",
        pauseOnHover: true,
        radius: "lg",
        shadow: "none",
        shadowColor: "none",
        showProgress: true,
        title: "Ошибка!",
        tone: "solid",
      });
    }
  };

  if (!course) return <div>Loading...</div>;

  return (
    <div className="max-w-2xl mx-auto p-4">
      <div className="fixed bottom-0 left-0 cursor-pointer flex justify-start items-start text-start text-xl">
        <div className="mx-2" onClick={() => copyToClip("❗")}>
          ❗
        </div>
        <div className="mx-2" onClick={() => copyToClip("✅")}>
          ✅
        </div>
        <div className="mx-2" onClick={() => copyToClip("❓")}>
          ❓
        </div>
        <div className="mx-2" onClick={() => copyToClip("👍")}>
          👍
        </div>
        <div className="mx-2" onClick={() => copyToClip("❌")}>
          ❌
        </div>
        <div className="mx-2" onClick={() => copyToClip("✔️")}>
          ✔️
        </div>
      </div>
      <h1 className="text-2xl font-bold mb-4">Редактирование записи</h1>
      <div className="mb-4">
        <label className="block text-gray-700">Внутреннее название ГК:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="intTitle"
          value={course.intTitle}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Внешнее название:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="extTitle"
          value={course.extTitle}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Тип:</label>
        <select
          className="w-full p-2 border border-gray-300 rounded"
          name="type"
          value={course.type}
          onChange={handleInputChange}
        >
          <option value="запись вебинара">запись вебинара</option>
          <option value="онлайн-курс">онлайн-курс</option>
          <option value="цикл вебинаров">цикл вебинаров</option>
          <option value="запись эфира">запись эфира</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Дескрипт:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="descript"
          value={course.descript}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Описание:</label>
        <textarea
          className="w-full p-2 border border-gray-300 rounded"
          name="about"
          value={course.about}
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Заметки:</label>
        <textarea
          className="w-full p-2 border border-gray-300 rounded"
          name="notes"
          value={course.notes}
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Цена:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="price"
          type="number"
          value={course.price}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Активно:</label>
        <input
          className="mr-2 leading-tight"
          name="active"
          type="checkbox"
          checked={course.active}
          onChange={(e) =>
            setCourse({ ...course, active: e.target.checked ? 1 : 0 })
          }
        />
        <span className="text-gray-700">Active</span>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Адрес лендинга:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="urlLanding"
          value={course.urlLanding}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Адрес курса на ГК:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="urlCourse"
          value={course.urlCourse}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">ГК ID:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="getcID"
          value={course.getcID}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">ГК продукт:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="getcProduct"
          value={course.getcProduct}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">ГК предложение:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="getcOffer"
          value={course.getcOffer}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">ГК Виджет:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="getcWidget"
          value={course.getcWidget}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Tilda:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="tilda"
          value={course.tilda}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Taplink:</label>
        <input
          className="w-full p-2 border border-gray-300 rounded"
          name="taplink"
          value={course.taplink}
          onChange={handleInputChange}
        />
      </div>
      <button
        className="bg-green-500 text-white p-2 rounded hover:bg-blue-700 mb-3 w-full"
        onClick={handleSave}
      >
        Сохранить
      </button>
      <button
        className="bg-red-500 text-white p-2 rounded hover:bg-blue-700 mb-6 w-full"
        onClick={handleCancel}
      >
        Отмена
      </button>
    </div>
  );
};

export default EditCourse;
