import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import {  useToast } from '@rewind-ui/core';


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export const copyToClip = async (text) => {
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const toast = useToast();
  try {
    await navigator.clipboard.writeText(text);
    toast.add({
      closeOnClick: true,
      color: 'green',
      description: text,
      duration: 1000,
      iconType: 'success',
      variant: 'success',
      pauseOnHover: true,
      radius: 'lg',
      shadow: 'none',
      shadowColor: 'none',
      showProgress: true,
      title: 'Скопировано!',
      tone: 'solid',
    });
 

  } catch (err) {
    toast.add({
      closeOnClick: true,
      color: 'red',
      description: text,
      duration: 1000,
      iconType: 'warning',
      variant: 'warning',
      pauseOnHover: true,
      radius: 'lg',
      shadow: 'none',
      shadowColor: 'none',
      showProgress: true,
      title: 'Ошибка!',
      tone: 'solid',
    });
  }
};