// src/components/Layout.js
import React from 'react';
import { ToastContainer, useToast } from '@rewind-ui/core';
import { Link } from 'react-router-dom';

const Layout = ({ children }) => {
  return (
    <div>
      <header>
{/*         <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
    
          </ul>
        </nav> */}
      </header>
      <main>
        {children}
      </main>
      <footer>
      
      </footer>
      <ToastContainer max="5" />
    </div>
  );
};

export default Layout;